import React, { useState, useEffect } from 'react';
import './Fade.scss';

const Fade = ({ children }) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  return (
    <div className={`fade ${isVisible ? 'fade-in' : ''}`}>
      {children}
    </div>
  );
};

export default Fade;