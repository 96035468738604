// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../layers/google.nodejs.yarn/yarn_modules/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../layers/google.nodejs.yarn/yarn_modules/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fade {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.fade-in {
  opacity: 1;
}`, "",{"version":3,"sources":["webpack://./src/Fade.scss"],"names":[],"mappings":"AAAA;EACE,UAAA;EACA,oCAAA;AACF;;AAEA;EACE,UAAA;AACF","sourcesContent":[".fade {\n  opacity: 0;\n  transition: opacity 0.5s ease-in-out;\n}\n\n.fade-in {\n  opacity: 1;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
