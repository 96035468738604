// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../layers/google.nodejs.yarn/yarn_modules/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../layers/google.nodejs.yarn/yarn_modules/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  padding: 0;
  font-family: "Raleway", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #4a525a;
  color: white;
}

.App {
  margin-left: 15px;
}

#root {
  width: 100%;
  height: 100%;
  min-height: 100%;
  background-attachment: fixed;
}

.App-header {
  margin: auto;
  text-align: center;
  margin: 5px;
}

h1, h2, h3 {
  font-family: "Oswald", sans-serif;
  letter-spacing: 0.2px;
}

a {
  font-size: 12px;
  color: white;
}

.projectParent {
  min-height: 220px;
}

.sectionTitle {
  padding: 0 15px;
}

.projectTitle {
  padding: 15px;
}

p {
  font-size: 12px;
}

.projectCarousel {
  overflow-x: hidden;
  width: 100%;
  display: flex;
  height: 220px;
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
}
.projectCarousel .projectCard {
  width: 320px;
  height: 220px;
  display: inline-block;
  margin: 15px;
}
.projectCarousel .projectCard .card--content {
  width: 320px;
  height: 220px;
  object-fit: cover;
}`, "",{"version":3,"sources":["webpack://./src/index.scss"],"names":[],"mappings":"AAAA;EACE,SAAA;EACA,UAAA;EACA,kCAAA;EACA,mCAAA;EACA,kCAAA;EACA,yBAAA;EACA,YAAA;AACF;;AAEA;EACE,iBAAA;AACF;;AAEA;EACG,WAAA;EACD,YAAA;EACA,gBAAA;EACA,4BAAA;AACF;;AAEA;EACI,YAAA;EACA,kBAAA;EACA,WAAA;AACJ;;AAEA;EACI,iCAAA;EACA,qBAAA;AACJ;;AACA;EACI,eAAA;EACA,YAAA;AAEJ;;AAAA;EACI,iBAAA;AAGJ;;AAGA;EACI,eAAA;AAAJ;;AAEA;EACI,aAAA;AACJ;;AAIA;EACI,eAAA;AADJ;;AAIA;EACI,kBAAA;EACA,WAAA;EACA,aAAA;EACA,aAAA;EACA,mBAAA;EACA,gBAAA;EACA,kBAAA;AADJ;AAGI;EACI,YAAA;EACA,aAAA;EACA,qBAAA;EACA,YAAA;AADR;AAEQ;EACI,YAAA;EACA,aAAA;EACA,iBAAA;AAAZ","sourcesContent":["body {\n  margin: 0;\n  padding: 0;\n  font-family: 'Raleway', sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n  background-color:#4a525a;\n  color: white;\n}\n\n.App {\n  margin-left: 15px;\n}\n\n#root {\n   width:100%;\n  height: 100%;\n  min-height: 100%;\n  background-attachment: fixed;\n}\n\n.App-header {\n    margin: auto;\n    text-align: center;\n    margin:5px;\n}\n\nh1,h2,h3 {\n    font-family: 'Oswald', sans-serif;\n    letter-spacing: 0.2px;\n}\na {\n    font-size:12px;\n    color:white;\n}\n.projectParent {\n    min-height: 220px;\n\n    //margin-top:10px;\n    //margin-bottom:10px;\n}\n\n.sectionTitle {\n    padding: 0 15px;\n}\n.projectTitle {\n    padding: 15px;\n}\n.projectItems {\n    //background-color:orange;\n}\np{\n    font-size:12px;\n}\n\n.projectCarousel {\n    overflow-x:hidden;\n    width: 100%;\n    display: flex;\n    height: 220px;\n    white-space:nowrap;\n    overflow-x: auto;\n    overflow-y: hidden;\n\n    .projectCard {\n        width: 320px;\n        height: 220px;\n        display: inline-block;\n        margin: 15px;\n        .card--content {\n            width: 320px;\n            height: 220px;\n            object-fit: cover;\n            //position: absolute;\n        }\n    }\n\n\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
